<script setup>
import { useHomeStore } from '~/stores/home.store';

const homeStore = useHomeStore();
const config = useRuntimeConfig();
const { isSafari, isIos } = useDevice();
const isSafariBrowser = () => {
	if (isSafari) {
		homeStore.showDownloadApp = false;
	}
	return isSafari;
}
const show = ref(!isSafariBrowser() || homeStore.showDownloadApp);
const hideDownloadApp = () => {
	homeStore.showDownloadApp = false;
	show.value = false;
}
const appId = useRuntimeConfig().public.appId;
</script>

<template>
	<div class="block bg-neutral-100" v-if="show">
		<div class="flex items-center relative bg-indigo-900 justify-between w-full px-4">
			<div class="flex items-center gap-2">
				<div class="flex items-center ">
					<img :src="`/images/${appId}/logo-download.png`" class="w-16 h-11 object-cover self-center rounded-lg" alt="vartur" />
				</div>
				<div class="flex flex-col text-white py-3 w-full">
					<h6 class="text-xs font-normal">{{ $t('client.mobile_app_title') }}</h6>
						<p class="mb-0 text-white/50">
							<small>{{ $t('client.mobile_app_sub_title') }}</small>
						</p>
				</div>
			</div>
			<a :href="config.public.websiteUrl" target="_blank" class="bg-white text-indigo-900 text-sm rounded-full  px-4 py-1 uppercase">
				{{ $t('client.mobile_app_open') }}
			</a>
			<!-- <span class="text-indigo-900 cursor-pointer absolute top-5 right-5" @click="hideDownloadApp">
				<i class="fa fa-close"></i>
			</span> -->
		</div>
	</div>
</template>
